import React, { Fragment, useState, useEffect, useRef, useMemo} from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux';
import base_url from '../../baseUrls';
import axios from 'axios'
import ApiUtils from '../../utils/apiUtils';
import Utils from '../../utils/utils';
import {Box, ThemeProvider, CircularProgress,  Typography} from '@mui/material';
import { ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Day, Week, Month, ExcelExport, Inject } from '@syncfusion/ej2-react-schedule';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
//import { extend, Internationalization, isNullOrUndefined, closest } from '@syncfusion/ej2-base';
//import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
//import { DropDownBnpmuttonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent, DropDownListComponent, MultiSelectComponent} from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import '@syncfusion/ej2-base/styles/material.css';  // Import the theme CSS
import './scheduleWrapper.css';
import { Internationalization, extend } from '@syncfusion/ej2-base';
import { USER_FIELDS, JOB_TYPES } from '../../constants/allConstants';
import { INSPECTION_REQUIREMENTS } from '../../constants/options/filterOptions/columnFilterOptions';
import { isContractor, isImpersonating } from '../../permissions/utils';  
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

class CustomUrlAdaptor extends UrlAdaptor {

    // // Override the `beforeSend` method
    // async beforeSend(request) {
    //     return request;
    // }

    // Override the processResponse method
    processResponse(data, success) {

        //console.log("return data => ", data);
        if (success) {
           return data.data;
        } else {
           // Handle errors or failed requests
           console.error('Request failed:', data);
           return [];
        }
    }
}

const  ScheduleWrapper  =  (props)  => {

    let scheduleObj = useRef(null);
    let dialogInstance = useRef(null);
    let authToken = useRef(null);
    const user = useSelector(state=> state.user);
    const company = useSelector(state => state.company);

    const [init] = useState(true);
    const [currentDate, setCurrentDate] = useState(moment());
    const [jobList, setJobList] = useState([]);  
    const [currentJob, setCurrentJob] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [subject, setSubject] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [dialogKey, setDialogKey] = useState(0);
    const [companyDataMgr, setCompanyDataMgr] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [companySelectable, setCompanySelectable] = useState(true); 
    const [jobTypeList, setJobTypeList] = useState([]);
    const [requirementList, setRequirementList] = useState([]);
    const [needLadderList, setNeedLadderList] = useState(['Ignore', 'Yes', 'No']);
    const [selectedJobType, setSelectedJobType] = useState([]);
    const [selectedRequirement, setSelectedRequirement] = useState([]);
    const [selectedNeedLadder, setSelectedNeedLadder] = useState('Ignore');
    const [toolbarTopMargin, setToolbarTopMargin] = useState(0);

    const queryCompany = new Query().take(30).skip(0);

    let instance = new Internationalization();
    const getDateHeaderText = (value) => {
        return instance.formatDate(value, { skeleton: 'Ed' });
    };
    const dateHeaderTemplate =  (props) => {

        console.log('props inside date header');
        console.log(props);

        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(props.date).toLocaleDateString('en-US', options);
        let selectedDate = props.date; 
        let startDate = new Date(selectedDate.setHours(0, 0, 0, 0));
        let endDate = new Date(selectedDate.setHours(23, 59, 59, 999));

        const filterJobList = jobList.filter ( (job) => {
            const jobStartTime = new Date(job.StartTime);
            jobStartTime.setHours(0,0,0,0);
            return (jobStartTime >= startDate & jobStartTime <= endDate);
        });
        return (<Fragment>
            <div className="weekly-date">{formattedDate}</div>
            <div className="count-text">Count: {filterJobList.length}</div>
        </Fragment>
        );       
    };

    const onRenderCell = (args) => {

        console.log('args - on rendercell');
        console.log(args);

        if (scheduleObj.current  == null) {
            console.log('schedule obj is null');
            return;
        }
        //console.log('current month view');
        //console.log(scheduleObj.current.currentView);

        if (args.elementType === 'monthCells'  && scheduleObj.current?.currentView === 'Month') 
        {
            //console.log('inside month cells');
            //console.log(jobList);

            let selectedDate = args.date; 
            let startDate = new Date(selectedDate.setHours(0, 0, 0, 0));
            let endDate = new Date(selectedDate.setHours(23, 59, 59, 999));

            const filterJobList = jobList.filter ( (job) => {

                //console.log('job => ', job);

                const jobStartTime = new Date(job.StartTime);
                jobStartTime.setHours(0,0,0,0);

                return (jobStartTime >= startDate & jobStartTime <= endDate);
            });

            // console.log('new event list');
    
            // console.log(filterJobList);
            // console.log('startDate => ', startDate.toString());
            // console.log('endDate => ', endDate.toString());


            let ele = document.createElement('div');
            if (filterJobList.length > 0)
            {
                ele.innerHTML = `<div class='count-text'>Count: ${filterJobList.length}</div>`;
                (args.element).appendChild(ele.firstChild);
            }
              
        }
    };

    const subjectStyle = {
        padding: '2px',
        lineHeight: '1',
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    };
      
    useEffect ( async () => {

        //console.log('init');
        console.log(Object.values(JOB_TYPES).map((s) => s.value));
        setJobTypeList(Object.values(JOB_TYPES).map((s) => s.value));
        setRequirementList(Object.values(INSPECTION_REQUIREMENTS).map((s) => s.value));

        const role = user?.[USER_FIELDS.ROLE.api_name];
        
        if(isContractor(role) || isImpersonating(role, user?.[USER_FIELDS.COMPANY_ID.api_name])) {
            setToolbarTopMargin(-51);  
            setCompanySelectable(false);
            setSelectedCompanyId(user?.[USER_FIELDS.COMPANY_ID.api_name]);
            return;

        }
        authToken.current = await ApiUtils.getAccessToken();
        const urlCompany = `${base_url.api}companies/autocomplete`;

        const compDataMgr = new DataManager({
            url: urlCompany,
            adaptor: new CustomUrlAdaptor(),
            crossDomain: true,
            offline: false,
            headers: [ {'Authorization': authToken.current}],
            onFailure: (e) => {
                console.error('API call failed', e);
            }
        });
        setCompanyDataMgr(compDataMgr);
    }, [init])

    const onChangeCompany = (args) => {

        if (args.itemData) {
            setSelectedCompanyId(args.itemData.companyId);           
        }
        else {
            setSelectedCompanyId('')
        }
    };


    const fetchScheduledJobs = async (startDate, endDate, selCompanyId) => {

        setIsLoading(true);
        setJobList([]);

        //startDate = startDate.setDate(startDate.getDate() + 1);
        //endDate = endDate.setDate(endDate.getDate() + 1);

        const model = {
            startDate: startDate.toString(),    //  Utils.formatIsoDateString(startDate, {date: true}),
            endDate: endDate.toString(),         //Utils.formatIsoDateString(endDate, {date: true}),
            companyId: selCompanyId,
            isContractor: !companySelectable,
            jobType: selectedJobType,
            requirement: selectedRequirement,
            needLadder: selectedNeedLadder === 'Ignore' ? '' : selectedNeedLadder,
        };

        //console.log('about to fetch jobs');
        //console.log(model);

        const authToken = await ApiUtils.getAccessToken();
        const url = `${base_url.api}schedule/get-inspections-by-sched-dates`;
        const response = await axios.post(url, model, {headers: {Authorization: authToken}});

        if (response.status === 200 && response.data) {
            setJobList(response.data.data);
        }
        setIsLoading(false);
    };

    useEffect ( () => {

        if (scheduleObj.current) {
            scheduleObj.current.refresh(); // Automatically refresh when data changes
        }
        //scheduleObj.current.refresh();
        // console.log('scroll to top in useEffect');

        // scheduleObj.current.refresh();
        if (scheduleObj.current.viewIndex === 0 || scheduleObj.current.viewIndex === 1) {
            scheduleObj.current.scrollTo('00:00');
        }
        //console.log('job list in use effect');
        //console.log(jobList);

    }, [jobList])

    useEffect(() => {

        if (currentJob == null) {
            return;
        }

        setSubject(currentJob.Subject);
        setStartTime(currentJob.StartTime);       
        setVisibleDialog(true);

        //setTimeout(() => {
        //    setVisibleDialog(true);
        //}, 5000);        

    }, [currentJob])


    useEffect(() => {
        if (selectedCompanyId) {
            const dates = scheduleObj.current.getCurrentViewDates();
            fetchScheduledJobs(dates[0], dates[dates.length - 1], selectedCompanyId);
        }
    }, [selectedCompanyId]);

    useEffect(() => {
        if (visibleDialog) {
            dialogInstance.current.show();
            setDialogKey(prev => prev + 1);
        }
        else {
            dialogInstance.current.hide();
        }
    }, [visibleDialog])

    const dlgBtnClose = () => {
        setVisibleDialog(false);
    };

    const dlgButtons = [{ click: dlgBtnClose, buttonModel: { content: 'Close', isPrimary: true } }];

    const dlgContent = (data) => {
        return (<table className="custom-event-editor" style={{ width: '100%' }} cellPadding={5}>
            <tbody>
                <tr>
                    <td className="e-textlabel">Job</td>
                    <td colSpan={4}>
                        {subject}
                    </td>
                </tr>
                {/* <tr>
                    <td className="e-textlabel">Date:</td>
                    <td colSpan={4}>
                        <DateTimePickerComponent id="StartTime" format='dd/MM/yy' data-name="StartTime" value={new Date(startTime)} className="e-field" readonly />
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Description:</td>
                    <td colSpan={4}>
                        {expenseDesc}
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Contact(s):</td>
                    <td colSpan={4}>
                        {contacts}                        
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Message:</td>
                    <td colSpan={4}>
                        <audio controls src={listenBlobUrl} style={{ width: '100%', height: '50px'}} />
                    </td>
                </tr> */}
            </tbody>
        </table>);
    };

    const onPopupOpen = async (args) => {
        
        if (args.type === "Editor") {
            args.cancel = true;  
            
            if (!args.data.Id) {
                console.log('no ID');
                return;
            }            
            const url = `${location.origin}/module/${args.data.Id}?moduleName=inspection&showSameAddressJobs=true`;

            window.open(url, '_blank');
        }
    };

    const onCreated = () => {

        if (selectedCompanyId) {
            const dates = scheduleObj.current.getCurrentViewDates();
            fetchScheduledJobs(dates[0], dates[dates.length - 1], selectedCompanyId);
        }
    }

    const onActionBegin = (args) => {
        if (args.requestType === 'toolbarItemRendering') {
            let exportItem = {
                align: 'Right', showTextOn: 'Both', prefixIcon: 'e-icons e-export-excel',
                text: 'Excel Export', cssClass: 'e-excel-export', click: onExportClick.bind(this)
            };
            args.items.push(exportItem);
        }
    };

    const onExportClick = () => {
        const exportFields = [
            { name: 'StartTime', text: 'Scheduled Date' },
            { name: 'Subject', text: 'Subject' },
            { name: 'Location', text: 'Address'},
            { name: 'JobType', text: 'Job Type'},
            { name: 'Requirement', text: 'Requirement'},
            { name: 'NeedLadder', text: 'Needs Ladder'},
            { name: 'JobNumber', text: 'Job Number'},
            { name: 'Description', text: 'Status'}
        ];
        const exportValues = { fieldsInfo: exportFields };
        scheduleObj.current.exportToExcel(exportValues);
    };

    const onActionComplete = (args) => {

        if (args.requestType == "viewNavigate" || args.requestType == "dateNavigate") {

            const dates = scheduleObj.current.getCurrentViewDates();

            // console.log('scroll to top');
            // console.log(dates);
            
            // console.log('lenght=> ', dates.length);
            // console.log('pass start date => ', dates[0]);
            // console.log('pass end date => ', dates[dates.length-1]);

            if (scheduleObj.current.viewIndex === 0 || scheduleObj.current.viewIndex === 1) {
                scheduleObj.current.scrollTo('00:00');
            }
            fetchScheduledJobs(dates[0], dates[dates.length-1], selectedCompanyId);


        }
    }

    const customTooltipWwrap = {
        backgroundColor: 'black',
        borderColor: 'black',
        color: 'white',
        padding: '2px',
        borderRadius: '5px',
        fontSize: '16px',
        width: '700px !important',
        height: '350px !important',
        maxWidth: '100%',
        overflow: 'auto',
    }

    const customTooltipTemplate = (props) => {
        return (
        <div className="tooltip-wrap" style={customTooltipWwrap}>
            <table border="1" cellPadding="4">
                <tbody>
                    <tr>
                        <td style={{ width: "120px"}}>Job #:</td>
                        <td>{props.JobNumber}</td>
                    </tr>
                    <tr>
                        <td>Job Type:</td>
                        <td>{props.JobType}</td>
                    </tr>
                    <tr>
                        <td>Requirement:</td>
                        <td>{props.Requirement}</td>
                    </tr>
                    <tr>
                        <td>Needs Ladder</td>
                        <td>{props.NeedLadder ? 'Yes' : "No"}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{props.Location}</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>{props.StartTime.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{props.Description}</td>
                    </tr>
                    {/* <tr>
                        <td>Date:</td>
                        <td>{props.EndTime.toLocaleString()}</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
      );
    };

    useEffect ( () => {

    
        if (selectedCompanyId) {
            const dates = scheduleObj.current.getCurrentViewDates();
            fetchScheduledJobs(dates[0], dates[dates.length - 1], selectedCompanyId);
        }

    }, [selectedJobType, selectedRequirement, selectedNeedLadder]);
    

    const onChangeJobType = (args) => {
        setSelectedJobType(args.value);
    };

    const onChangeRequirement = (args) => {
        setSelectedRequirement(args.value);
    };

    const onChangeNeedLadder = (args) => {
        setSelectedNeedLadder(args.value);
    };

    const jobTypeRef = useRef(null);
    const requirementRef = useRef(null);

    const handleFocusJobType = () => {
        if (jobTypeRef.current) {
            jobTypeRef.current.showPopup(); // Show dropdown on focus
        }
    };

    const handleFocusRequirement = () => {
        if (requirementRef.current) {
            requirementRef.current.showPopup(); // Show dropdown on focus
        }
    };

    return (
        <>
            <style>
                {`
                    .e-appbar.e-primary {
                        height: 65px;
                    }
                    .custom-appbar .e-appbar-items {
                        display: flex;
                        justify-content: flex-start;
                        height: auto;
                    }
                    .appbar-content {
                        display: flex;
                        flex-direction: row;
                        font-size: 14px;
                        margin: 20px 0 15px;
                        
                    }                     
                    .e-multi-select-wrapper {
                        background-color: white;
                        width: 100%; 
                        max-height: 35px;
                        overflow-y: auto;
                    }
                    custom-clear-icon .e-clear-icon::before {
                        color: red; /* Change this to your desired color */
                    }

                `}
            </style>
            <div id='mainContainer' style={{ marginTop: `${toolbarTopMargin}px`}}>  
                <AppBarComponent colorMode="Primary" className="custom-appbar">
                    {companySelectable && <>              
                        {companyDataMgr && <div className="appbar-content" style={{ width: '470px', marginRight: '20px'}}>
                            <label>
                                Company
                                <AutoCompleteComponent id="company"  sortOrder="Ascending"
                                    dataSource={companyDataMgr} query={queryCompany}
                                    fields={{ value: 'companyName', text: 'companyName'}}                                               
                                    minLength={3} filterType='Contains'
                                    change={onChangeCompany}
                                    cssClass="custom-clear-icon"
                                    style={{ backgroundColor: 'white', color: 'black', width: '450px', maginRight: '15px',  paddingLeft: '4px' }}                              />
                            </label>
                         </div>}                         
                    </>
                    }   
                    {!companySelectable && <>   
                        <div className="appbar-content" style={{ width: '470px', marginRight: '20px'}}>
                            <label>
                                Company
                                <TextBoxComponent value={company.name} readOnly floatLabelType="Never" 
                                     style={{ backgroundColor: 'white', color: 'black', width: '450px', maginRight: '15px',  paddingLeft: '4px' }}
                                />
                            </label>                         
                        </div> 
                    </>
                    } 
                    <div className="appbar-content" style={{  marginRight: '20px'}} >
                        <label>
                            Job Type
                            <MultiSelectComponent id="jobType" ref={jobTypeRef} dataSource={jobTypeList} placeholder="Select a job type" mode="Box" 
                            cssClass="custom-multiselect" popupHeight={`${jobTypeList.length * 40}px`}
                            allowObjectBinding={false} 
                            value={selectedJobType} change={onChangeJobType} focus={handleFocusJobType}     />     
                        </label>
                    </div>
                    <div className="appbar-content" style={{ marginRight: '20px'}} > 
                        <label>
                            Requirement
                            <MultiSelectComponent id="requirement" ref={requirementRef} dataSource={requirementList} placeholder="Select a requirement" mode="Box" 
                            cssClass="custom-multiselect"  popupHeight={`${requirementList.length * 40}px`} 
                            allowObjectBinding={false} 
                            value={selectedRequirement} change={onChangeRequirement} focus={handleFocusRequirement} />     
                        </label>
                    </div>
                    <div className="appbar-content" style={{ width: '120px'}}  >
                        <label>
                            Need Ladder
                            <DropDownListComponent id="ddlNeedLadder" dataSource={needLadderList}  placeholder="" 
                             value={selectedNeedLadder} 
                             popupHeight="120px" style={{ backgroundColor: 'white', paddingLeft: '2px'}}
                             change={onChangeNeedLadder}  /> 
                        </label>
                    </div>
                </AppBarComponent>
                {isLoading && <div  className="appbar-content" style={{ display: 'flex', alignItems: 'center', gap: '10px', whiteSpace: 'nowrap', paddingRight: '15px'}}>
                            <CircularProgress variant="indeterminate" color="success" />
                                <Typography color="red" variant="h6">
                                    Geting Scheduled Jobs.  Please wait!
                                </Typography>                
                            </div>}  
                <ScheduleComponent ref={scheduleObj} width='100%' height='650px' selectedDate={currentDate}
                    eventSettings={{ 
                        dataSource: jobList, 
                        allowAdding: false, 
                        enableTooltip: true, 
                        tooltipTemplate: customTooltipTemplate.bind(this),
                        template: (props) => (
                            
                            <div style={subjectStyle}>
                              <div>
                                {props.Subject}
                                {scheduleObj.current.viewIndex === 0 &&
                                    <span>, {props.Location}</span>
                                }
                              </div>
                            </div>
                          ),
                    }}
                    className='main-schedule'
                    currentView={'Month'} created={onCreated} actionBegin={onActionBegin} actionComplete={onActionComplete}
                    popupOpen={onPopupOpen}  showQuickInfo={false}  
                    renderCell={onRenderCell} 
                >
                    <ViewsDirective>
                        <ViewDirective option='Day' />
                        <ViewDirective option='Week'   />
                        <ViewDirective option='Month' />
                    </ViewsDirective>
                    <Inject services={[Day, Week, Month, ExcelExport]} />
                </ScheduleComponent>
                <DialogComponent id="defaultdialog" key={dialogKey}  showCloseIcon={true} animationSettings={{ effect: 'None' }} width={'500px'} visible={visibleDialog}
                    ref={dialogInstance} target={'#mainContainer'} header={"Call Detail"} buttons={dlgButtons} content={dlgContent}>
                </DialogComponent>
            </div>
        </>

    );
}

export default ScheduleWrapper;